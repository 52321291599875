module.exports = {
	"selectors": {
		"date": "Дата",
		"card_brand": "Брэнд карты",
		"card_type": "Тип карты",
		"card_country": "Страна карты",
		"customer_type": "Тип игрока",
		"widget_mode": "Режим виджета",
		"last_status_change": "По дате последней смены статуса",
		"processor": "Процессинг",
		"payment_system": "ПС",
		"payment_type": "Тип платежа",
		"type": "Тип",
		"status": "Статус",
		"country": "Страна",
		"customer_country": "Страна игрока",
		"token_country": "Страна по IP",
		"customer_or_token_country": "Страна (игрока или IP)",
		"currency": "Валюта",
		"customer_currency": "Валюта игрока",
		"processor_currency": "Валюта процессинга",
		"fee_currency": "Валюта комиссии",
		"order_currency": "Валюта заявки",
		"error_type": "Тип отказа",
		"error_subtype": "Подтип отказа",
		"purse": "Кошелек",
		"user": "Пользователь",
		"no_user": "Нет пользователя",
		"old_status": "Старый статус",
		"new_status": "Новый статус",
		"bank": "Банк",
		"no_bank": "Нет банка",
		"platform": "Платформа",
		"name_or_url": "Название или URL",
		"refcodes": "Рефкоды",
		"log_type": "Тип",
		"log_action": "Действие",
		"elements_per_page": "Количество элементов на странице",
		"billing_currency": "Валюта платежа",
		"integration_status": "Статус",
		"system_payment_type": "Тип платежа",
		"continent": "Континент",
		"period": "Период",
		"sort_type": "Сортировка",
		"fee_type": "Комиссия",
		"alert_type": "Тип",
		"processor_channel": "Канал процессинга",
		"no_processor_channel": "Нет канала процессинга",
		"billing_types": "Тип платежа",
		"event_log_types": "Тип",
		"others": "Другие",
		"supervising_manager": "Курирующий менеджер",
		"callback_status": "Статус",
		"title": "Название",
		"ftd": "FTD",
		"three_ds_status": "3DS статус",
		"purse_country": "Страна кошелька",
		"no_purse_country": "Нет страны кошелька",
		"amount": "Сумма",
		"failure_code": "Код отказа",
		"failure_message": "Сообщение отказа",
		"no_failure_type": "Без типа отказа",
		"show_unused": "Показывать неиспользуемое",
		"customer_types": {
			"real": "Реальный",
			"test": "Тестовый",
			"test_partner": "Тестовый партнер"
		},
		"dates": {
			"from": "С",
			"to": "по",
			"whole_period": "Весь период",
			"last_month": "Прошлый месяц",
			"current_month": "Этот месяц",
			"thirty_days": "30 дней",
			"seven_days": "7 дней",
			"week": "Неделя",
			"yesterday": "Вчера",
			"today": "Сегодня"
		},
		"placeholder": {
			"default": "Ничего не выбрано",
			"purse": "Введите номер кошелька",
			"comma_separated": "Введите через запятую",
			"name_or_url": "Введите название процессинга или URL"
		},
		"helpers": {
			"select_all": "Выбрать все",
			"clear": "Очистить",
			"search": "Поиск"
		},
		"country_areas": {
			"ru": "Российская Федерация",
			"cis": "СНГ",
			"euro_union": "Европа (Евросоюз)",
			"not_euro_union": "Европа (Не Евросоюз)",
			"west_asia": "Западная Азия",
			"east_asia": "Восточная Азия",
			"africa": "Африка",
			"australia_and_oceania": "Австралия и Океания",
			"south_america": "Южная Америка",
			"north_america": "Северная Америка",
			"others": "Другие"
		},
		"order_columns_title": "Колонки",
		"order_columns": {
			"amount": "Сумма",
			"bank_id": "Банк",
			"billing_amount": "Сумма платежа",
			"billing_currency": "Валюта платежа",
			"billing_type": "Тип платежа",
			"refundable_amount": "Сумма, доступная для рефанда",
			"created_at": "Дата создания",
			"customer": "Игрок",
			"customer_country": "Страна игрока",
			"customer_fee": "Наша комиссия",
			"deposit_number": "Номер депозита",
			"failure_code": "Код отказа",
			"failure_type": "Тип отказа",
			"id": "ID заявки",
			"jurisdiction": "Юрисдикция",
			"locale": "Локаль",
			"order_currency": "Валюта заявки",
			"personal_fee": "Персональная комиссия",
			"platform": "Платформа",
			"processor_account_title": "Аккаунт процессинга",
			"processed_at": "Дата обработки процессингом",
			"processor_channel_id": "Канал процессинга",
			"processor_fee": "Комиссия процессинга (расчетная)",
			"processor_fee_external": "Комиссия процессинга (внешняя)",
			"processor_name": "Процессинг",
			"project_fee": "Комиссия проекта",
			"project_name": "Проект",
			"purse": "Кошелек",
			"refcode": "Рефкоды",
			"rejection_reason": "Причина отклонения",
			"scheduled_at": "Выплата запланирована",
			"secondary_txids": "Вторичные ID",
			"status": "Статус",
			"status_updated_at": "Дата обновления статуса",
			"support_links": "Доп. ссылки",
			"support_status": "Статус суппорта",
			"system_name": "Система",
			"three_ds_status": "3DS статус",
			"token_country": "Страна по IP",
			"txid": "ID транзакции",
			"type": "Тип",
			"updated_at": "Заявка обновлена",
			"user": "Пользователь",
			"purse_country": "Страна кошелька",
			"card_type": "Тип карты"
		},
		"deposit_default_amount_strategy": {
			"last_deposit": "Последний депозит",
			"average": "Среднее",
			"rule_or_rating": "Правило или рейтинг",
			"max_of_last_deposit_and_average": "Максимальное из последнего и среднего депозита"
		},
		"widget_screen": {
			"deposit": "Депозит",
			"withdraw": "Выплата",
			"deposit-form": "Форма депозита",
			"withdraw-form": "Форма выплаты",
			"clarification-form": "Форма кларификации",
			"frame": "Фрейм ПС",
			"form": "Форма ПС",
			"confirmation": "Подтверждение",
			"instruction": "Инструкция",
			"message": "Сообщение"
		},
		"vwo": {
			"name": "Вариация VWO",
			"not_participating": "<Не участвует>"
		},
		"card_types": {
			"no_data": "Нет данных"
		},
		"timerange": {
			"time": "Время",
			"start": "Начало",
			"end": "Конец"
		}
	}
};