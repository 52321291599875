module.exports = {
	"errors": {
		"balances": {
			"wrong_jurisdiction": "Некорректная юрисдикция",
			"missing_date": "Отсутствует дата",
			"bad_amount": "Сумма должна быть больше 0"
		},
		"banks": {
			"not_found": "Банк не найден",
			"title_presence": "Необходимо указать название банка",
			"uniqueness": "Как-то неловко вышло, но такое имя банка уже занято",
			"try_later": "Попробуйте позже"
		},
		"common": {
			"access_failed": "Ошибка доступа",
			"project_not_found": "Проект не найден",
			"invalid_url": "Неправильный URL",
			"must_one_jurisdiction": "Необходимо выбрать хотя бы одну юрисдикцию",
			"invalid_jurisdiction": "Некорректная юрисдикция",
			"invalid_deposit_limits": "Некорректно заданы лимиты депозитов",
			"invalid_withdraw_limits": "Некорректно заданы лимиты выплат"
		},
		"csp": {
			"not_found": "Правило не найдено",
			"invalid_params": "Невалидные параметры"
		},
		"customers": {
			"not_found": "Не удалось найти игрока",
			"no_token": "Не удалось открыть виджет (отсутствует токен)"
		},
		"invalid_token": "Ошибка доступа",
		"order_comments": {
			"text_presence": "Необходимо указать текст комментария"
		},
		"orders": {
			"bank_card": "Карта не подтверждена",
			"banned_customer": "Забаненный игрок",
			"daily_limit": "Превышен дневной лимит выплат, сумма: {excess}",
			"incorrect_action": "Некорректное действие",
			"incorrect_reject_reason": "Некорректная причина отклонения",
			"incorrect_status": "Заявка имеет некорректный статус",
			"incorrect_time": "Некорректное время оплаты",
			"many_results": "Найдено более одной заявки",
			"monthly_limit": "Превышен месячный лимит выплат, сумма: {excess}",
			"not_found": "Заявка не найдена",
			"support_status": "Заявка помечена суппортом как непоплачиваемая",
			"suspicious_customer": "Подозрительный игрок",
			"test_customer": "Тестовый игрок",
			"update_status": "Неверный статус, попробуйте обновить страницу",
			"weekly_limit": "Превышен недельный лимит выплат, сумма: {excess}",
			"not_refundable": "Невозможно сделать рефанд",
			"refund_amount_invalid": "Некорректная сумма рефанда"
		},
		"processors": {
			"not_found": "Процессинг не найден",
			"comment_presence_if_disabled": "Комментарий обязательный, если процессинг выключен"
		},
		"projects": {
			"already_exists": "Проект с таким названием уже существует",
			"api_key_presence": "Необходимо указать API ключ проекта",
			"name_presence": "Необходимо указать название проекта",
			"not_found": "Проект не найден",
			"not_unique_id": "Указан неуникальный ID сайта",
			"not_valid_settings": "Невалидные настройки {project}",
			"shortname_presence": "Необходимо указать сокращенное название проекта",
			"table_sync_id_exists": "Проект с таким table_sync_id уже существует",
			"title_presence": "Необходимо указать заголовок проекта",
			"wrong_api_endpoint": "Неверный формат API адреса",
			"wrong_homepage_url": "Неверный формат homepage url",
			"wrong_template_format": "Неверный формат шаблона ссылки"
		},
		"reports": {
			"too_many_results": "Слишком много результатов, попробуйте уменьшить период и/или количество группировок",
			"invalid_period": "Период не может быть больше 31 дня"
		},
		"system_settings": {
			"deposits_unsupported": "Система не поддерживает депозиты",
			"incorrect_locale": "Некорректная локаль",
			"not_found": "Система не найдена",
			"withdraws_unsupported": "Система не поддерживает выплаты"
		},
		"systems": {
			"not_found": "Система не найдена",
			"incorrect_type": "Некорректный тип",
			"incorrect_deposit_timeout": "Таймаут депозита должен быть больше 0",
			"comment_presence_if_disabled": "Комментарий обязательный, если система выключена"
		},
		"users": {
			"not_found": "Пользователь не найден"
		},
		"payout_bot": {
			"daily_limit": "Превышен дневной лимит выплат, сумма: {excess}",
			"weekly_limit": "Превышен недельный лимит выплат, сумма: {excess}",
			"monthly_limit": "Превышен месячный лимит выплат, сумма: {excess}",
			"customer_autopay_disabled": "Автооплата для игрока отключена",
			"internal": "Выплаты для внутренних игроков запрещены",
			"project_autopay_disabled": "Автооплата для проекта отключена",
			"suspicious_customer": "Подозрительный игрок",
			"documents_not_approved": "Документы не подтверждены",
			"bank_card": "Карта не подтверждена",
			"no_deposits": "Нет депозитов с кошелька",
			"low_balance": "Недостаточный баланс",
			"test_customer": "Тестовый игрок",
			"banned_customer": "Игрок забанен",
			"support_status": "Заявка помечена суппортом как непоплачиваемая",
			"nemesis_not_verified": "Заявка не проверена в Nemesis",
			"autopay_disabled": "Заявка в ручной обработке",
			"autopay_limits": "Превышен суточный лимит автовыплат",
			"autopay_amount": "Сумма выплаты больше лимита автовыплат"
		},
		"alerts": {
			"not_found": "Алерт не найден",
			"invalid_until": "Невалидная дата"
		},
		"test_widget": {
			"one_click_unavailable": "1-click недоступен"
		},
		"processor_callbacks": {
			"period_too_large": "Период слишком большой (максимум {maxDays} дней)",
			"invalid_content_type": "Невалидный content_type",
			"processor_not_found": "Процессинг не найден",
			"invalid_callback": "Невалидный коллбек: {message}",
			"not_found": "Не удалось найти коллбек",
			"invalid_status": "Нельзя отправить коллбек повторно, потому что он в неверном статусе"
		},
		"order_failure_codes": {
			"non_uniq_record": "Данный код для текущего процессинга уже существует"
		},
		"processor_accounts": {
			"invalid_account": "Невалидный аккаунт",
			"invalid_yaml": "Невалидный YAML. Строка: {line}, колонка: {column}",
			"invalid_title": "Невалидное название",
			"invalid_processor_name": "Выбранный процессинг не поддерживает аккаунты",
			"invalid_jurisdictions": "Невалидные юрисдикции",
			"invalid_account_data": "Невалидные данные аккаунта",
			"already_exists": "Аккаунт процессинга с данными параметрами уже существует",
			"not_found": "Аккаунт процессинга не найден"
		},
		"free_deposit_limits": {
			"not_test_entity": "Некорректный тип аккаунта",
			"no_limits_provided": "Хотя бы один лимит должен быть задан",
			"invalid_limits": "Лимиты должны удовлетворять условию дневной <= недельный <= месячный"
		},
		"rule_segments": {
			"no_segment_down": "Внизу нет сегмента",
			"ambiguous_segments": "Неоднозначный приоритет сегмента снизу"
		},
		"rule_actions": {
			"invalid_processor_account": "Невалидный ID аккаунта процессинга",
			"already_exists": "Такое действие уже существует",
			"no_segment_down": "Внизу нет сегмента",
			"ambiguous_segments": "Неоднозначный приоритет сегмента снизу"
		},
		"pages": {
			"not_found": "Ошибка: 404 Страницы не существует",
			"access_denied": "Ошибка: 403 Нет доступа",
			"something_went_wrong": "Ошибка: 500 Что-то пошло не так"
		},
		"card": {
			"unable_to_unban": "Ошибка при попытке разбана карты",
			"not_banned": "Карта не забанена"
		}
	}
};